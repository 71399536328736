@tailwind base;
@tailwind components;
@tailwind utilities;

/* Regular */
@font-face {
	font-family: "Circular";
	src: url(./assets/fonts/Circular\ Std\ Book.ttf);
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "Circular";
	src: url(./assets/fonts/Circular\ Std\ Bold\ Italic.ttf);
	font-style: italic;
	font-weight: 400;
}

/* Regular */
@font-face {
	font-family: "Circular";
	src: url(./assets/fonts/Circular\ Std\ Medium.ttf);
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: "Circular";
	src: url(./assets/fonts/Circular\ Std\ Medium\ Italic.ttf);
	font-style: italic;
	font-weight: 500;
}

/* Regular */
@font-face {
	font-family: "Circular";
	src: url(./assets/fonts/Circular\ Std\ Bold.ttf);
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: "Circular";
	src: url(./assets/fonts/Circular\ Std\ Bold\ Italic.ttf);
	font-style: italic;
	font-weight: 700;
}

* {
	box-sizing: border-box;

	font-family: "Circular";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	-webkit-touch-callout: none;
	/* -webkit-user-select: none; */
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
}

body {
	font-family: "Circular";
	width: 100%;
	height: 100%;

	color: #333333;
}

.stats {
	right: 0;
	left: initial !important;
}

.stats > * {
	color: white !important;
}

:root {
	--tp-base-background-color: #2f313780;
}

.tp-rotv {
	position: absolute;
	top: 140px;
	backdrop-filter: blur(8px);
}

.Toastify__toast {
	padding: 0;
	min-height: 0;
}

.Toastify__toast-body {
	margin: 0;
}
